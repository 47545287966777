import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Module from '../components/Module'

const locale = process.env.GATSBY_LOCALE

export const query = graphql`
  query($id: String) {
    page: sanityPage(id: { eq: $id }) {
      id
      title {
        enUS
        svSE
      }
      description {
        enUS
        svSE
      }
      mainImage {
        asset {
          localFile {
            publicURL
          }
        }
      }
      _rawModules(resolveReferences: { maxDepth: 2 })
    }
  }
`

const IndexPage = ({ data: { page }, location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO
      title={page.title[locale]}
      description={page.description[locale]}
      image={page.mainImage.asset.localFile.publicURL}
      path={pathname}
    />
    {page._rawModules &&
      page._rawModules.map((module, idx) => (
        <Module
          key={module._key ?? module._id}
          module={module}
          first={idx === 0}
          even={idx % 2 === 0}
        />
      ))}
  </Layout>
)

export default IndexPage
